<template>
  <div>
    <b-modal
      id="commission-switch-auth"
      ref="commission-switch-auth-ref"
      hide-footer
      @hidden="clearPin"
    >
      <b-form-group>
        <label>Security Pin</label>
        <b-form-input placeholder="*****" v-model="security_pin" />
        <br />
        <b-button @click="commissionSwitchAuth">Submit</b-button>
      </b-form-group>
    </b-modal>
    <b-card>
      <b-media no-body>
        <b-media-aside>
          <b-link>
            <b-img
              v-if="user_profile_image"
              rounded
              :src="FILESURL + user_profile_image"
              height="80"
            />
            <b-img
              v-else
              rounded
              src="@/assets/images/avatars/profile_img.png"
              height="80"
            />
          </b-link>
        </b-media-aside>

        <b-media-body class="mt-75 ml-75">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            size="sm"
            class="mb-75 mr-75"
            @click="$refs.refInputEl.$el.click()"
          >
            Upload
          </b-button>
          <b-form-file
            ref="refInputEl"
            accept=".jpg, .png, .gif"
            :hidden="true"
            plain
            v-model="uploaded_image"
            @input="uploadProfileImage"
          />
          <b-card-text>Allowed JPG, GIF or PNG. Max size of 800kB</b-card-text>
        </b-media-body>
      </b-media>

      <!-- form -->
      <b-form class="mt-2">
        <b-row>
          <b-col sm="6">
            <b-form-group label="Firstname" label-for="account-firstname">
              <b-form-input
                v-model="user_firstname"
                placeholder="Firstname"
                name="account-firstname"
                id="account-firstname"
                :disabled="true"
              />
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group label="Lastname" label-for="account-lastname">
              <b-form-input
                v-model="user_lastname"
                name="account-lastname"
                id="account-lastname"
                placeholder="Lastname"
                :disabled="true"
              />
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group label="E-mail" label-for="account-email">
              <b-form-input
                v-model="user_email"
                name="account-email"
                id="account-email"
                placeholder="Email"
                :disabled="true"
              />
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group label="Phone Number" label-for="account-mobile">
              <vue-tel-input
                id="register-mobile"
                v-model="user_mobile"
                name="register-mobile"
                class="py-25"
                v-on:country-changed="countryChanged"
                :dropdownOptions="{
                  showFlags: true,
                  showSearchBox: true,
                  showDialCodeInSelection: true,
                }"
                :inputOptions="{
                  placeholder: 'Enter phone number',
                  required: true,
                  showDialCode: false,
                }"
                defaultCountry="IN"
                :disabled="true"
              ></vue-tel-input>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </b-card>
    <b-card
      v-if="
        ['agent', 'franchise'].includes(user_type) &&
        $store.getters['user/getUserDetails'].show_commission === 'Y'
      "
    >
      <p>Show Commission</p>
      <b-form-checkbox
        v-model="commission_switch"
        v-b-modal.commission-switch-auth
        switch
      >
      </b-form-checkbox>
    </b-card>
  </div>
</template>

<script>
import {
  BFormFile,
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BAlert,
  BCard,
  BCardText,
  BMedia,
  BMediaAside,
  BMediaBody,
  BLink,
  BImg,
  BFormCheckbox,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import CommonServices from "@/apiServices/CommonServices";
import { BASEURL, FILESURL } from "@/config/index";
import { TokenService } from "@/apiServices/storageService";
import axios from "axios";
import ToastificationContentVue from "@/@core/components/toastification/ToastificationContent.vue";
import store from "@/store";
import AgentServices from "@/apiServices/AgentServices";

export default {
  components: {
    BButton,
    BForm,
    BImg,
    BFormFile,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BAlert,
    BCard,
    BCardText,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
    BFormCheckbox,
  },
  directives: {
    Ripple,
  },
  props: {},
  data() {
    return {
      uploaded_image: null,
      user_profile_image: null,
      user_firstname: "",
      user_lastname: "",
      user_email: "",
      // user_country_code: "",
      user_mobile: "",
      commission_switch: false,
      FILESURL,
      security_pin: null,
    };
  },
  computed: {
    user_type() {
      if (store.getters["user/getUserDetails"].user_type) {
        return store.getters["user/getUserDetails"].user_type;
      } else {
        return null;
      }
    },
  },
  methods: {
    countryChanged(input) {
      if (this.user_mobile && this.user_mobile.split(" ").length > 1) {
        this.user_mobile = "+" + input.dialCode + " " + this.user_mobile;
      } else {
        this.user_mobile = "+" + input.dialCode;
      }
    },
    async getUserProfile() {
      const response = await CommonServices.getUserProfile();

      const user_profile = response.data.data;
      console.log("ARS", user_profile);

      this.user_profile_image = user_profile.user_profile_image;
      this.user_firstname = user_profile.user_firstname;
      this.user_lastname = user_profile.user_lastname;
      this.user_email = user_profile.user_email;
      this.user_mobile = user_profile.user_mobile;
      this.user_country_code = user_profile.user_country_code;
      this.commission_switch =
        user_profile.commission_switch === "Y" ? true : false;

      this.$store.commit("user/SET_USER_DETAILS", user_profile);
    },
    async uploadProfileImage() {
      try {
        const AUTH_TOKEN = TokenService.getToken();
        const formData = new FormData();
        formData.append("image", this.uploaded_image);
        const response = await axios.post(
          `${BASEURL}/common/upload-profile-image`,
          formData,
          {
            headers: {
              "content-type": "multipart/form-data",
              "Access-Control-Allow-Origin": "*",
              authorization: AUTH_TOKEN,
            },
          }
        );

        if (response.data.status) {
          this.getUserProfile();
        } else {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: response.data.message || "Profile Image not Uploaded",
              icon: "EditIcon",
              variant: "failure",
            },
          });
        }
      } catch (err) {
        console.log("Error uploading profile image ", err);
      }
    },
    clearPin() {
      this.security_pin = null;
      this.getUserProfile();
    },
    async commissionSwitchAuth() {
      try {
        const response = await AgentServices.commissionSwitchAuth({
          security_pin: this.security_pin,
        });

        if (response.data.status) {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: response.data.message || "Success",
              icon: "EditIcon",
              variant: "success",
            },
          });
          this.$refs["commission-switch-auth-ref"].hide();
        } else {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: response.data.message || "Failed",
              icon: "EditIcon",
              variant: "failure",
            },
          });
        }
      } catch (error) {
        console.log("Error in commissionSwitchAuth ", error);
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: "Request Failed",
            icon: "EditIcon",
            variant: "failure",
          },
        });
      }
    },
  },
  beforeMount() {
    this.getUserProfile();
  },
};
</script>
